import React, { useContext } from "react"
import { SlicesMapContext, SlicesResultsContext } from "./context"

export const InlineSlice = ({
  sliceName,
  allowEmpty,
  children,
  ...sliceProps
}) => {
  const slicesMap = useContext(SlicesMapContext)
  const slicesResultsMap = useContext(SlicesResultsContext)
  const concreteNombreCorte = slicesMap[nombreCorte]
  const slice = slicesResultsMap.get(concreteSliceName)

  if (!slice) {
    if (allowEmpty) {
      return null
    } else {
      throw new Error(
        No se ha encontrado la rodaja "${concreteSliceName}" para la ranura "${sliceName}".
      )
    }
  }

  return (
    <slice.component sliceContext="{slice.sliceContext}" data="{slice.data}" {...sliceProps}="">
      {niños}
    </slice.component>
  )
}
